import Vue from 'vue'
import axios from 'axios'
import {
  contentType,
  debounce,
  invalidCode,
  noPermissionCode,
  requestTimeout,
  successCode,
  tokenName,
  loginInterception,
} from '@/config'
import store from '@/store'
import qs from 'qs'
import router from '@/router'
import { isArray } from '@/utils/validate'
let Base64 = require('js-base64').Base64
let baseURL = Base64.decode(process.env.VUE_APP_BASE_API) + "/api"  //加bsse连接加密（加密）
//let baseURL = process.env.VUE_APP_BASE_API + "/api"       //不加bsse连接加密（明文）
let loadingInstance

/**
 * @description 处理code异常
 * @param {*} code
 * @param {*} msg
 */
const handleCode = (url, msg) => {
  let noAlertData = ['addOrder']
  for (let i = 0; i < noAlertData.length; i++) {
    if (url.includes(noAlertData[i])) {
      return
    }
  }
  let apiData = ['getByUserMoney']

  let isRefLogin = false

  for (let i = 0; i < apiData.length; i++) {
    if (url.includes(apiData[i])) {
      isRefLogin = true;
      break
    }
  }

  if (msg?.includes('Token') || msg?.includes('token') || msg?.includes('权限')) {
    let info = 'Token已失效，请重新登录'
    store.dispatch('user/getUserInfo', '')
    localStorage.removeItem('token')
    localStorage.removeItem('uName')
    localStorage.removeItem('uID')
    router.push('/login')
    Vue.prototype.$global.baseToast(info)
  } else if (isRefLogin) {
    store.dispatch('user/getUserInfo', '')
    localStorage.removeItem('token')
    localStorage.removeItem('uName')
    localStorage.removeItem('uID')
    // router.push('/login')
    // Vue.prototype.$global.baseToast(msg)
  } else {
    Vue.prototype.$global.baseToast(msg)
  }
  // switch (code) {
  //   case invalidCode:
  //     Vue.prototype.$global.baseNotify(msg || `后端接口${code}异常`, 'danger')
  //     store.dispatch('user/resetAccessToken').catch(() => { })
  //     if (loginInterception) {
  //       location.reload()
  //     }
  //     break
  //   case noPermissionCode:
  //     router.push({ path: '/401' }).catch(() => { })
  //     break
  //   default:
  //     Vue.prototype.$global.baseNotify(msg || `后端接口${code}异常`, 'danger')
  //     break
  // }
}

const instance = axios.create({
  baseURL,
  timeout: requestTimeout,
  headers: {
    'Content-Type': contentType,
  },
})

instance.interceptors.request.use(
  (config) => {
    // if (store.getters['user/accessToken']) {
    //   config.headers[tokenName] = store.getters['user/accessToken']
    // }
    //这里会过滤所有为空、0、false的key，如果不需要请自行注释
    // if (config.data)
    //   config.data = Vue.prototype.$baseLodash.pickBy(
    //     config.data,
    //     Vue.prototype.$baseLodash.identity
    //   )
    if (
      config.data &&
      config.headers['Content-Type'] ===
      'application/x-www-form-urlencoded;charset=UTF-8'
    )
      config.data = qs.stringify(config.data)
    // if (debounce.some((item) => config.url.includes(item)))
    if (config.url.includes('UserRegister')) {
      // loadingInstance = Vue.prototype.$global.baseLoading('正在注册中')
    } else if (config.url.includes('UserLogin')) {
      // loadingInstance = Vue.prototype.$global.baseLoading('正在登录中')
    } else {
      let arr = [
        '/Lottery/getAmKJ',
        '/Lottery/getxAmKJ',
        '/Lottery/getxxAmKJ',
        '/Lottery/getXgKJ',
        '/Lottery/getXgOpenList',
        '/Lottery/getXgNextTime',
        "/Forum/getForumType",
        "/Lottery/getTwKJ",
        "/Lottery/getXjbKJ",
        "/Forum/getCMSListByPage"
      ]
      if (!arr.includes(config.url) && !loadingInstance) {
        // loadingInstance = Vue.prototype.$global.baseLoading('加载中')
      }
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

instance.interceptors.response.use(
  (response) => {
    if (loadingInstance) {
      loadingInstance.clear()
      loadingInstance = ''
    }

    const { data, config } = response
    // const { code, message, flag } = data
    const { success, message } = data
    // 操作正常Code数组
    // const codeVerificationArray = isArray(successCode)
    //   ? [...successCode]
    //   : [...[successCode]]
    // 是否操作正常

    if (success) {
      return data
    } else {
      handleCode(config.url, message)
      return Promise.reject(data)
    }
  },
  (error) => {
    if (loadingInstance) {
      loadingInstance.clear()
      loadingInstance = ''
    }
    const { response, message } = error
    if (error.response && error.response.data) {
      const { status, data } = response
      handleCode("error", data.message || message)
      return Promise.reject(error)
    } else {
      let { message } = error
      if (message === 'Network Error') {
        message = '网络异常'
      }
      if (message.includes('timeout')) {
        message = '后端接口请求超时'
      }
      if (message.includes('Request failed with status code')) {
        const code = message.substr(message.length - 3)
        message = '后端接口' + code + '异常'
      }
      // Vue.prototype.$global.baseNotify(message || `后端接口未知异常`, 'danger')
      Vue.prototype.$global.baseToast(message)
      return Promise.reject(error || 'error')
    }
  }
)

export default instance
