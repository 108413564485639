import { render, staticRenderFns } from "./MyRoll.vue?vue&type=template&id=6cb78b94&scoped=true&"
import script from "./MyRoll.vue?vue&type=script&lang=js&"
export * from "./MyRoll.vue?vue&type=script&lang=js&"
import style0 from "./MyRoll.vue?vue&type=style&index=0&id=6cb78b94&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6cb78b94",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\work\\tukuv2\\home\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6cb78b94')) {
      api.createRecord('6cb78b94', component.options)
    } else {
      api.reload('6cb78b94', component.options)
    }
    module.hot.accept("./MyRoll.vue?vue&type=template&id=6cb78b94&scoped=true&", function () {
      api.rerender('6cb78b94', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Draw/MyRoll.vue"
export default component.exports