var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "home-draw" }, [
    _c("div", { staticClass: "number_draw" }, [
      _c("div", { staticClass: "nd_top" }, [
        _c("div", { staticClass: "nd_td1" }, [
          _c("h4", [_vm._v(_vm._s(_vm.czName))]),
          _c("p", [
            _c("span", [
              _vm._v(
                " " +
                  _vm._s(_vm._f("formatTime")(_vm.nextOpenTime, "MM")) +
                  "月" +
                  _vm._s(_vm._f("formatTime")(_vm.nextOpenTime, "DD")) +
                  "日 "
              ),
            ]),
            _vm._v(" 开奖 "),
          ]),
        ]),
        _c("div", { staticClass: "nd_td2" }, [
          _c("div", [
            _c("b", [_vm._v("第")]),
            _c("span", [_vm._v(_vm._s(_vm.lotteryPeriod))]),
            _c("b", [_vm._v("期")]),
          ]),
        ]),
        _c("div", { staticClass: "nd_td3", on: { click: _vm.jumeKj } }, [
          _vm._v("直播"),
        ]),
        _c(
          "a",
          { attrs: { href: "javascript:;" }, on: { click: _vm.jumpRecord } },
          [_vm._v("开奖记录")]
        ),
      ]),
      _c("div", { staticClass: "nd_border" }, [
        _c(
          "div",
          { staticClass: "nd_plural" },
          [
            _vm.lotteryData?.openCode
              ? _vm._l(_vm.lotteryData.openCode, function (item, i) {
                  return _c("div", { key: i, staticClass: "boll-box" }, [
                    _c(
                      "strong",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: i != 6,
                            expression: "i != 6",
                          },
                        ],
                        class: _vm.lotteryData.bose[i],
                      },
                      [_vm._v(" " + _vm._s(item) + " ")]
                    ),
                    _c(
                      "p",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: i != 6,
                            expression: "i != 6",
                          },
                        ],
                      },
                      [
                        _c("span", [_vm._v(_vm._s(_vm.lotteryData.sxiao[i]))]),
                        _vm.lotteryData.wxing[i]
                          ? _c("b", [_vm._v("/")])
                          : _vm._e(),
                        !_vm.lotteryData.wxing[i]
                          ? _c("b", { staticStyle: { opacity: "0" } }, [
                              _vm._v("/"),
                            ])
                          : _vm._e(),
                        _c("span", [_vm._v(_vm._s(_vm.lotteryData.wxing[i]))]),
                      ]
                    ),
                    _c(
                      "h3",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: i == 6,
                            expression: "i == 6",
                          },
                        ],
                      },
                      [_vm._v(_vm._s(item))]
                    ),
                  ])
                })
              : _vm._e(),
          ],
          2
        ),
        _c("div", { staticClass: "nd_info" }, [
          _c("p", [
            _c("span", [
              _vm._v(" 下期开奖日期: "),
              _c("b", [_vm._v(_vm._s(_vm.nextOpenTime))]),
            ]),
            _c(
              "span",
              [
                _vm._v(" 直播倒计时: "),
                _c("van-count-down", {
                  attrs: { time: _vm.timeDown },
                  on: { change: _vm.onChange, finish: _vm.onFinish },
                }),
              ],
              1
            ),
            _c("span", [
              _c("b", { on: { click: _vm.getLotteryData2 } }, [
                _vm._v(_vm._s("刷新")),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }