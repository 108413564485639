<template>
  <div class="home-draw">
    <div class="number_draw">
      <div class="nd_top">
        <div class="nd_td1">
          <h4>{{ czName }}</h4>
          <p>
            <span>
              {{ nextOpenTime | formatTime('MM') }}月{{
                nextOpenTime | formatTime('DD')
              }}日
            </span>
            开奖
          </p>
        </div>
        <div class="nd_td2">
          <div>
            <b>第</b>
            <span>{{ lotteryPeriod }}</span>
            <b>期</b>
          </div>
        </div>
        <div class="nd_td3" @click="jumeKj">直播</div>
        <!-- <router-link to="/record">开奖记录></router-link> -->
        <a href="javascript:;" @click="jumpRecord">开奖记录</a>
        <!-- <router-link to="/login">开奖记录></router-link> -->
      </div>
      <div class="nd_border">
        <div class="nd_plural">
          <template v-if="lotteryData?.openCode">
            <div
              v-for="(item, i) in lotteryData.openCode"
              :key="i"
              class="boll-box"
            >
              <strong v-show="i != 6" :class="lotteryData.bose[i]">
                {{ item }}
              </strong>
              <p v-show="i != 6">
                <span>{{ lotteryData.sxiao[i] }}</span>
                <b v-if="lotteryData.wxing[i]">/</b>
                <b v-if="!lotteryData.wxing[i]" style="opacity: 0">/</b>
                <span>{{ lotteryData.wxing[i] }}</span>
              </p>
              <h3 v-show="i == 6">{{ item }}</h3>
            </div>
          </template>
        </div>
        <div class="nd_info">
          <p>
            <span>
              下期开奖日期:
              <b>{{ nextOpenTime }}</b>
            </span>
            <span>
              直播倒计时:
              <van-count-down
                :time="timeDown"
                @change="onChange"
                @finish="onFinish"
              />
            </span>
            <span>
              <b @click="getLotteryData2">{{ '刷新' }}</b>
            </span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getAmOpen,
  getxAmOpen,
  getxxAmOpen,
  getXgOpen,
  getXgNextTime,
  getTwKJ,
  getXjbKJ,
} from '@/api/lottery'
export default {
  name: 'VueVant2AppDraw',
  props: {
    isCountDown: Boolean,
    curlottery: String | Number,
    lotteryFeilei: Array,
  },
  data() {
    return {
      timeDown: 0,
      lotteryData: {},
      nextOpenTime: new Date(),
      timer: null,
      czName: '',
    }
  },
  watch: {
    // timeDown(val) {
    //   let ten = 24 * 60 * 60 * 1000
    //   if (val < ten) {lotteryData
    //     debugger;
    //     // this.getLotteryData()
    //     console.log("nextOpenTime",val);
    //   }
    // },
  },
  computed: {
    lotteryPeriod() {
      let curlottery = this.curlottery
      let period = 0
      if (this.lotteryData?.period) {
        period = this.lotteryData?.period.slice(-3)
        // if (curlottery == 90003) {
        //   period = this.lotteryData?.period.slice(-4)
        // }
      }
      return period
    },
  },
  async mounted() {
    this.getLotteryData()
    clearInterval(this.timer)
    this.timer = null
    this.setTimer()
    this.lotteryFeilei.forEach((item) => {
      if (item.gameNo == this.curlottery) {
        this.czName = item.gameName
      }
    })
  },
  methods: {
    setTimer() {
      if (this.timer == null) {
        this.timer = setInterval(() => {
          let curTime = new Date().getTime()
          let st = '21:31'
          let et = '21:34'
          let curlottery = this.curlottery
          if (curlottery == '90001') {
            st = '21:32'
            et = '21:35'
           
          }
          if (curlottery == '90002') {
            st = '21:32'
            et = '21:36'
          }
          if (curlottery == '90003') {
            st = '20:50'
            et = '20:53'
          }
          if (curlottery == '90005') {
            st = '21:00'
            et = '21:05'
            // st = '13:00'
            // et = '13:58'
           
          }
          if (curlottery == '90006') {
            st = '23:30'
            et = '23:40'
            // st = '13:00'
            // et = '13:58'
           
          }
          let startTime = this.$formatTime(new Date(), 'YYYY-MM-DD') + ' ' + st
          let endTime = this.$formatTime(new Date(), 'YYYY-MM-DD') + ' ' + et
          let startTime2 = new Date(startTime).getTime()
          let endTime2 = new Date(endTime).getTime()
          // if (
          //   openTime <= to40min ||
          //   (curTime > startTime2 && curTime < endTime2)
          // ) {
          //   this.getLotteryData()
          // }
          if (curTime > startTime2 && curTime < endTime2) {
            let day = new Date().getDay()

            if (curlottery == '90003') {
              if (day == 2 || day == 4) {
                this.getLotteryData2()
              }
            } else {
              this.getLotteryData2()
            }
          }
        }, 2000)
      }
    },
    onFinish() {
      // this.getLotteryData()
      this.$emit('shuaxin')
      this.getLotteryData()
    },
    getLotteryData() {
      let curlottery = this.curlottery
      if (curlottery == '90004') {
        this.getAmOpen()
        this.getAmOpenTime()
      } else if (curlottery == '90005') {
        this.getxAmOpen()
        this.getxAmOpenTime()
      }else if (curlottery == '90006') {
        this.getxxAmOpen()
        this.getxxAmOpenTime()
      }  else if (curlottery == '90003') {
        this.getTwKJ()
        this.getTwOpenTime()
      } else if (curlottery == '90002') {
        this.getXjbKJ()
        this.getXjbOpenTime()
      } else {
        this.getXgOpen()
        this.getXgNextTime()
      }
    },
    getLotteryData2() {
      let curlottery = this.curlottery
      if (curlottery == '90004') {
        this.getAmOpen()
      } else if (curlottery == '90005') {
        this.getxAmOpen()
      }else if (curlottery == '90006') {
        this.getxxAmOpen()
      }else if (curlottery == '90003') {
        this.getTwKJ()
      } else if (curlottery == '90002') {
        this.getXjbKJ()
      } else {
        this.getXgOpen()
      }
    },
    splitData(obj) {
      let newObj = {}
      for (let key in obj) {
        if (obj[key].includes(',')) {
          let arr = obj[key].split(',')
          arr.splice(-1, 0, '+')
          newObj[key] = arr
        } else {
          newObj[key] = obj[key]
        }
      }
      return newObj
    },
    onChange(TimeData) {
      // let curlottery = this.curlottery
      // let days = TimeData.days * 24 * 60 * 60 * 1000
      // let hours = TimeData.hours * 60 * 60 * 1000
      // let minutes = TimeData.minutes * 60 * 1000
      // let seconds = TimeData.seconds * 1000
      // let milliseconds = TimeData.milliseconds
      // let to40min = 40 * 60 * 1000
      // let openTime = days + hours + minutes + seconds + milliseconds
      // let curTime = new Date().getTime()
      // let startTime = this.$formatTime(new Date(), 'YYYY-MM-DD') + ' 21:30'
      // let endTime = this.$formatTime(new Date(), 'YYYY-MM-DD') + ' 21:50'
      // if (curlottery == 90002) {
      //   startTime = this.$formatTime(new Date(), 'YYYY-MM-DD') + ' 18:30'
      //   endTime = this.$formatTime(new Date(), 'YYYY-MM-DD') + ' 18:50'
      // }
      // if (curlottery == 90003) {
      //   startTime = this.$formatTime(new Date(), 'YYYY-MM-DD') + ' 20:50'
      //   endTime = this.$formatTime(new Date(), 'YYYY-MM-DD') + ' 21:10'
      // }
      // let startTime2 = new Date(startTime).getTime()
      // let endTime2 = new Date(endTime).getTime()
      // if (openTime <= to40min || (curTime > startTime2 && curTime < endTime2)) {
      //   this.getLotteryData()
      // }
    },
    async getAmOpen() {
      let { list: data } = await getAmOpen()
      if (data) this.lotteryData = this.splitData(data[0])
      // console.log(this.lotteryData)
    },
    async getxAmOpen() {
      let { list: data } = await getxAmOpen()
      if (data) this.lotteryData = this.splitData(data[0])
      // console.log(this.lotteryData)
    },
    async getxxAmOpen() {
      let { list: data } = await getxxAmOpen()
      if (data) this.lotteryData = this.splitData(data[0])
      // console.log(this.lotteryData)
    },
    async getXgOpen() {
      let { list: data } = await getXgOpen()
      if (data) this.lotteryData = this.splitData(data[0])
      // console.log(this.lotteryData)
    },
    async getTwKJ() {
      let { list: data } = await getTwKJ()
      if (data) this.lotteryData = this.splitData(data[0])
    },
    async getXjbKJ() {
      let { list: data } = await getXjbKJ()
      if (data) this.lotteryData = this.splitData(data[0])
    },
    // 获取倒计时数据
    getCountDown(time) {
      if (!time) return 0
      let nextTime = new Date(time).getTime()
      let curTime = new Date().getTime()
      return nextTime - curTime
    },
    async getXgNextTime() {
      let { list } = await getXgNextTime()
      this.nextOpenTime = list[0]?.FOpenDate + ' ' + list[0]?.FOpenTime
      this.timeDown = this.getCountDown(this.nextOpenTime)
    },
    getAmOpenTime() {
      let curDate = this.$formatTime(new Date(), 'YYYY-MM-DD') + ' 21:31'
      var oneDay = 1000 * 60 * 60 * 24 //一天的毫秒数
      if (new Date().getTime() > new Date(curDate).getTime()) {
        let nextDay = new Date().getTime() + oneDay
        curDate = this.$formatTime(new Date(nextDay), 'YYYY-MM-DD') + ' 21:31'
      }
      this.nextOpenTime = curDate
      this.timeDown = this.getCountDown(curDate)
    },
    getxAmOpenTime() {
      let curDate = this.$formatTime(new Date(), 'YYYY-MM-DD') + ' 21:00'
      var oneDay = 1000 * 60 * 60 * 24 //一天的毫秒数
      if (new Date().getTime() > new Date(curDate).getTime()) {
        let nextDay = new Date().getTime() + oneDay
        curDate = this.$formatTime(new Date(nextDay), 'YYYY-MM-DD') + ' 21:00'
      }
      this.nextOpenTime = curDate
      this.timeDown = this.getCountDown(curDate)
    },
    getxxAmOpenTime() {
      let curDate = this.$formatTime(new Date(), 'YYYY-MM-DD') + ' 23:30'
      var oneDay = 1000 * 60 * 60 * 24 //一天的毫秒数
      if (new Date().getTime() > new Date(curDate).getTime()) {
        let nextDay = new Date().getTime() + oneDay
        curDate = this.$formatTime(new Date(nextDay), 'YYYY-MM-DD') + ' 23:30'
      }
      this.nextOpenTime = curDate
      this.timeDown = this.getCountDown(curDate)
    },
    getTwOpenTime() {
      let day = new Date().getDay()
      if (day == 0) {
        let oneDay = 1000 * 60 * 60 * 24 * 2
        let nextTime = new Date().getTime() + oneDay
        let curDate =
          this.$formatTime(new Date(nextTime), 'YYYY-MM-DD') + ' 20:50'
        this.nextOpenTime = curDate
        this.timeDown = this.getCountDown(curDate)
      } else if (day == 1) {
        let oneDay = 1000 * 60 * 60 * 24
        let nextTime = new Date().getTime() + oneDay
        let curDate =
          this.$formatTime(new Date(nextTime), 'YYYY-MM-DD') + ' 20:50'
        this.nextOpenTime = curDate
        this.timeDown = this.getCountDown(curDate)
      } else if (day == 2) {
        let curDate = this.$formatTime(new Date(), 'YYYY-MM-DD') + ' 20:50'
        var oneDay = 1000 * 60 * 60 * 24 * 3
        if (new Date().getTime() > new Date(curDate).getTime()) {
          let nextDay = new Date().getTime() + oneDay
          curDate = this.$formatTime(new Date(nextDay), 'YYYY-MM-DD') + ' 20:50'
        }
        this.nextOpenTime = curDate
        this.timeDown = this.getCountDown(curDate)
      } else if (day == 3) {
        let oneDay = 1000 * 60 * 60 * 24 * 2
        let nextTime = new Date().getTime() + oneDay
        let curDate =
          this.$formatTime(new Date(nextTime), 'YYYY-MM-DD') + ' 20:50'
        this.nextOpenTime = curDate
        this.timeDown = this.getCountDown(curDate)
      } else if (day == 4) {
        let oneDay = 1000 * 60 * 60 * 24
        let nextTime = new Date().getTime() + oneDay
        let curDate =
          this.$formatTime(new Date(nextTime), 'YYYY-MM-DD') + ' 20:50'
        this.nextOpenTime = curDate
        this.timeDown = this.getCountDown(curDate)
      } else if (day == 5) {
        let curDate = this.$formatTime(new Date(), 'YYYY-MM-DD') + ' 20:50'
        var oneDay = 1000 * 60 * 60 * 24 * 4
        if (new Date().getTime() > new Date(curDate).getTime()) {
          let nextDay = new Date().getTime() + oneDay
          curDate = this.$formatTime(new Date(nextDay), 'YYYY-MM-DD') + ' 20:50'
        }
        this.nextOpenTime = curDate
        this.timeDown = this.getCountDown(curDate)
      } else if (day == 6) {
        let oneDay = 1000 * 60 * 60 * 24 * 3
        let nextTime = new Date().getTime() + oneDay
        let curDate =
          this.$formatTime(new Date(nextTime), 'YYYY-MM-DD') + ' 20:50'
        this.nextOpenTime = curDate
        this.timeDown = this.getCountDown(curDate)
      }
    },
    getXjbOpenTime() {
      let curDate = this.$formatTime(new Date(), 'YYYY-MM-DD') + ' 21:33'
      var oneDay = 1000 * 60 * 60 * 24 //一天的毫秒数
      if (new Date().getTime() > new Date(curDate).getTime()) {
        let nextDay = new Date().getTime() + oneDay
        curDate = this.$formatTime(new Date(nextDay), 'YYYY-MM-DD') + ' 21:33'
      }
      this.nextOpenTime = curDate
      this.timeDown = this.getCountDown(curDate)
    },
    //
    getXjbOpenTime2() {
      let day = new Date().getDay()
      if (day == 0) {
        let oneDay = 1000 * 60 * 60 * 24 * 1
        let nextTime = new Date().getTime() + oneDay
        let curDate =
          this.$formatTime(new Date(nextTime), 'YYYY-MM-DD') + ' 18:40'
        this.nextOpenTime = curDate
        this.timeDown = this.getCountDown(curDate)
      } else if (day == 1) {
        let curDate = this.$formatTime(new Date(), 'YYYY-MM-DD') + ' 18:40'
        var oneDay = 1000 * 60 * 60 * 24 * 3
        if (new Date().getTime() > new Date(curDate).getTime()) {
          let nextDay = new Date().getTime() + oneDay
          curDate = this.$formatTime(new Date(nextDay), 'YYYY-MM-DD') + ' 18:40'
        }
        this.nextOpenTime = curDate
        this.timeDown = this.getCountDown(curDate)
      } else if (day == 2) {
        let oneDay = 1000 * 60 * 60 * 24 * 2
        let nextTime = new Date().getTime() + oneDay
        let curDate =
          this.$formatTime(new Date(nextTime), 'YYYY-MM-DD') + ' 18:40'
        this.nextOpenTime = curDate
        this.timeDown = this.getCountDown(curDate)
      } else if (day == 3) {
        let oneDay = 1000 * 60 * 60 * 24 * 1
        let nextTime = new Date().getTime() + oneDay
        let curDate =
          this.$formatTime(new Date(nextTime), 'YYYY-MM-DD') + ' 18:40'
        this.nextOpenTime = curDate
        this.timeDown = this.getCountDown(curDate)
      } else if (day == 4) {
        let curDate = this.$formatTime(new Date(), 'YYYY-MM-DD') + ' 18:40'
        var oneDay = 1000 * 60 * 60 * 24 * 4
        if (new Date().getTime() > new Date(curDate).getTime()) {
          let nextDay = new Date().getTime() + oneDay
          curDate = this.$formatTime(new Date(nextDay), 'YYYY-MM-DD') + ' 18:40'
        }
        this.nextOpenTime = curDate
        this.timeDown = this.getCountDown(curDate)
      } else if (day == 5) {
        let oneDay = 1000 * 60 * 60 * 24 * 3
        let nextTime = new Date().getTime() + oneDay
        let curDate =
          this.$formatTime(new Date(nextTime), 'YYYY-MM-DD') + ' 18:40'
        this.nextOpenTime = curDate
        this.timeDown = this.getCountDown(curDate)
      } else if (day == 6) {
        let oneDay = 1000 * 60 * 60 * 24 * 2
        let nextTime = new Date().getTime() + oneDay
        let curDate =
          this.$formatTime(new Date(nextTime), 'YYYY-MM-DD') + ' 18:40'
        this.nextOpenTime = curDate
        this.timeDown = this.getCountDown(curDate)
      }
    },
    getDay(date) {
      let day = new Date(date).getDay()
      let val = ''
      switch (day) {
        case 0:
          val = '天'
          break
        case 1:
          val = '一'
          break
        case 2:
          val = '二'
          break
        case 3:
          val = '三'
          break
        case 4:
          val = '四'
          break
        case 5:
          val = '五'
          break
        case 6:
          val = '六'
          break
      }
      return '星期' + val
    },
    jumpRecord() {
      let curlottery = this.curlottery
      let path = '/record/' + curlottery
      this.$router.push(path)
    },
    jumeKj() {
      let curlottery = this.curlottery
      let path = '/video/' + curlottery
      this.$router.push(path)
    },
  },
  destroyed() {
    // 每次离开当前界面时，清除定时器
    clearInterval(this.timer)
    this.timer = null
  },
}
</script>

<style lang="scss" scoped>
.home-draw {
  margin-top: 0.2rem;
  .nd_border {
    border: solid 1px;
    border-color: #c22424;
  }
  .number_draw {
    display: flex;
    flex-direction: column;
    // margin: 0 0.266667rem;
    // min-height: 3.946667rem;
    // border: solid 1px;
    border-color: #c22424;
    border-bottom-left-radius: 11px;
    border-bottom-right-radius: 11px;
    background-color: #fff;

    .nd_top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0.246667rem 0.133333rem 0.133333rem;
      font-size: 0.32rem;
      background: #c22424;
      border-radius: 0.233333rem 0.233333rem 0 0;
      color: #fff;
      span {
        color: #fff;
      }
      a {
        position: relative;
        color: #fff;
        font-size: 0.626667rem;
        font-weight: 600;
      }
      .nd_td1 {
        display: flex;
        flex-direction: column;
        align-items: center;

        h4 {
          font-size: 0.426667rem;
          font-weight: 600;
        }
        p {
          transform: scale(0.8);
          text-align: center;
        }
      }
      .nd_td2 {
        font-size: 0.7rem;
        font-weight: 600;
        b {
          font-weight: 600;
        }
        span {
          color: #fceb01;
        }
      }
      .nd_td3 {
        display: flex;
        align-items: center;
        line-height: 1;
        padding: 0.18rem 0.3rem;
        font-size: 0.5rem;
        background: #d31e1e;
        border-radius: 100px;
      }
    }
    .nd_plural {
      display: flex;
      -ms-flex-pack: distribute;
      justify-content: space-around;

      align-items: center;
      padding: 0.2rem;
      padding-bottom: unset;
      min-height: 1.413333rem;
      div {
        width: 10%;
        h3 {
          padding-bottom: 0.533333rem;
          font-size: 0.666667rem;
          font-weight: 400;
          text-align: center;
        }
        strong {
          display: inline-block;
          width: 0.906667rem;
          height: 0.906667rem;
          line-height: 0.906667rem;
          text-align: center;
          font-size: 0.373333rem;
          font-weight: 500;
          background-image: url(../../assets/images/home/mod_icon_ball.png);
          background-repeat: no-repeat;
          background-size: 500% 100%;
          background-position: -3.626667rem 0;
          &.red {
            background-position: 0 0;
          }
          &.blue {
            background-position: -0.906667rem 0;
          }
          &.green {
            background-position: -1.813333rem 0;
          }
          &.ball_4 {
            background-position: -3.626667rem 0;
          }
        }
        p {
          padding-top: 0.133333rem;
          font-size: 0.32rem;
          text-align: center;
        }
      }
    }
    .nd_info {
      padding: 0.273333rem 0 0.166667rem;
      display: flex;
      justify-content: center;
      align-items: center;
      p {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 0.4rem;
        width: 100%;
        span {
          display: flex;
          align-items: baseline;
          font-size: 0.32rem;
          line-height: 1;
          color: #999;
          zoom: 0.9;
          b {
            color: red;
          }
          .van-count-down {
            font-size: 0.32rem;
            color: red;
          }
        }
      }
      > span {
        font-size: 0.32rem;
        color: #999;
      }
    }
  }
}
</style>
