var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "limitation-width" }, [
    _c(
      "div",
      { staticClass: "gallery-head" },
      [
        _c(
          "van-tabs",
          { attrs: { ellipsis: false }, on: { click: _vm.czTab } },
          _vm._l(_vm.lotteryData, function (item, i) {
            return _c("van-tab", { key: i, attrs: { title: item.gameName } })
          }),
          1
        ),
      ],
      1
    ),
    _c("div", { staticClass: "gallery-main" }, [
      _c("div", { staticClass: "gallery-search" }, [
        _c("div", { staticClass: "search-box" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.searchVal,
                expression: "searchVal",
              },
            ],
            attrs: { type: "text", placeholder: "請輸入您要查找的图纸名称" },
            domProps: { value: _vm.searchVal },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.searchVal = $event.target.value
              },
            },
          }),
          _c(
            "div",
            { staticClass: "gicon", on: { click: _vm.getDataPhoto } },
            [_c("van-icon", { attrs: { name: "search" } })],
            1
          ),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "gallery-order" },
        [
          _c(
            "van-tabs",
            {
              attrs: { ellipsis: false },
              on: { click: _vm.orderTab },
              model: {
                value: _vm.activeName,
                callback: function ($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName",
              },
            },
            _vm._l(_vm.allEn, function (item, i) {
              return _c("van-tab", { key: i, attrs: { name: i, title: item } })
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "gallery-cont" },
        [
          _vm.photoData
            ? _c(
                "div",
                { staticClass: "list" },
                [
                  _vm.activeName != 0
                    ? _vm._l(
                        Object.entries(_vm.photoData),
                        function (item, index) {
                          return _c("div", { key: index }, [
                            _c("h2", [_vm._v(_vm._s(item[0]))]),
                            _c(
                              "div",
                              { staticClass: "gallery-list" },
                              _vm._l(item[1], function (valItem) {
                                return _c(
                                  "div",
                                  {
                                    key: valItem.FID,
                                    staticClass: "gallery-item",
                                    on: {
                                      click: function ($event) {
                                        return _vm.jumpDetail(valItem)
                                      },
                                    },
                                  },
                                  [
                                    _c("img", {
                                      attrs: { src: valItem.FImg, alt: "" },
                                    }),
                                    _c("div", { staticClass: "gallery-info" }, [
                                      _c("h4", [
                                        _vm._v(_vm._s(valItem.FSubType1)),
                                      ]),
                                      _c("span", [
                                        _vm._v(_vm._s(valItem.FName)),
                                      ]),
                                    ]),
                                  ]
                                )
                              }),
                              0
                            ),
                          ])
                        }
                      )
                    : [
                        _c(
                          "div",
                          { staticClass: "gallery-list" },
                          _vm._l(_vm.photoList, function (valItem) {
                            return _c(
                              "div",
                              {
                                key: valItem.FID,
                                staticClass: "gallery-item",
                                on: {
                                  click: function ($event) {
                                    return _vm.jumpDetail(valItem)
                                  },
                                },
                              },
                              [
                                _c("img", {
                                  attrs: { src: valItem.FImg, alt: "" },
                                }),
                                _c("div", { staticClass: "gallery-info" }, [
                                  _c("h4", [_vm._v(_vm._s(valItem.FSubType1))]),
                                  _c("span", [_vm._v(_vm._s(valItem.FName))]),
                                ]),
                              ]
                            )
                          }),
                          0
                        ),
                      ],
                ],
                2
              )
            : _c("van-empty", { attrs: { description: "暂无数据" } }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }