var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "limitation-width fixed-box" },
    [
      _c("van-nav-bar", {
        attrs: { fixed: "", title: _vm.$route.query.uName },
        on: { "click-left": _vm.goback },
        scopedSlots: _vm._u([
          {
            key: "left",
            fn: function () {
              return [
                _c("van-icon", {
                  attrs: { name: "arrow-left", color: "#fff", size: "18" },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "div",
        { staticClass: "cur-head" },
        [
          _c(
            "van-tabs",
            {
              attrs: { ellipsis: false },
              on: { click: _vm.imgTab },
              model: {
                value: _vm.activeName,
                callback: function ($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName",
              },
            },
            _vm._l(_vm.photoData, function (item, i) {
              return _c("van-tab", {
                key: i,
                attrs: { name: i, title: item.FName },
              })
            }),
            1
          ),
          _c(
            "div",
            { staticClass: "cur-more" },
            [
              _c(
                "van-dropdown-menu",
                [
                  _c(
                    "van-dropdown-item",
                    {
                      ref: "dropdownItemRef",
                      scopedSlots: _vm._u([
                        {
                          key: "title",
                          fn: function () {
                            return [
                              _c("img", {
                                attrs: {
                                  src: "/images/app-561b2105.png",
                                  alt: "",
                                },
                              }),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "more-list" },
                        _vm._l(_vm.photoData, function (item, i) {
                          return _c(
                            "div",
                            {
                              key: i,
                              staticClass: "more-item",
                              on: {
                                click: function ($event) {
                                  return _vm.imgTab(i)
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(item.FName) + " ")]
                          )
                        }),
                        0
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "cur-main" }, [
        _vm.curPhotoData.length
          ? _c("div", { staticClass: "cur-img" }, [
              _c("img", { attrs: { src: _vm.curPhotoData[0].FImg, alt: "" } }),
              _c(
                "div",
                {
                  staticClass: "cur-keep",
                  on: {
                    click: function ($event) {
                      return _vm.setKeep(_vm.curPhotoData[0])
                    },
                  },
                },
                [
                  _vm.keepList.indexOf(_vm.curPhotoData[0].FID) != -1
                    ? _c("van-icon", { attrs: { name: "star" } })
                    : _c("van-icon", { attrs: { name: "star-o" } }),
                ],
                1
              ),
              _c("div", { staticClass: "cur-btns" }, [
                _c(
                  "div",
                  {
                    staticClass: "cur-btn",
                    on: {
                      click: function ($event) {
                        return _vm.nextImg(1)
                      },
                    },
                  },
                  [
                    _c("van-icon", { attrs: { name: "arrow-left" } }),
                    _c("span", [_vm._v("上一期")]),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "cur-btn",
                    on: {
                      click: function ($event) {
                        return _vm.nextImg(2)
                      },
                    },
                  },
                  [
                    _c("span", [_vm._v("下一期")]),
                    _c("van-icon", { attrs: { name: "arrow" } }),
                  ],
                  1
                ),
              ]),
            ])
          : _vm._e(),
        _vm.otherPhotoData.length
          ? _c("div", { staticClass: "other-img" }, [
              _c("h2", [_vm._v("往期图纸推荐")]),
              _c(
                "ul",
                _vm._l(_vm.otherPhotoData, function (item, i) {
                  return _c(
                    "li",
                    {
                      key: i,
                      on: {
                        click: function ($event) {
                          return _vm.jumpDetail(item)
                        },
                      },
                    },
                    [
                      _c("img", { attrs: { src: item.FImg, alt: "" } }),
                      _c("div", { staticClass: "other-desc" }, [
                        _c("h4", [_vm._v(_vm._s(item.FSubType1))]),
                        _c("p", [
                          _c("span", [_vm._v(_vm._s(item.FName))]),
                          _c("span", [_vm._v(_vm._s(item.FRead) + "人看过")]),
                        ]),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "cur-keep",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.setKeep(item)
                            },
                          },
                        },
                        [
                          _vm.keepList.indexOf(item.FID) != -1
                            ? _c("van-icon", { attrs: { name: "star" } })
                            : _c("van-icon", { attrs: { name: "star-o" } }),
                        ],
                        1
                      ),
                    ]
                  )
                }),
                0
              ),
            ])
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }