import request from '@/utils/request'

// 读取澳门开奖
// export function getAmOpen(data) {
//   return request({
//     url: '/Lottery/getAmOpen',
//     method: 'post',
//     data,
//   })
// }
export function getAmOpen(data) {
  return request({
    url: '/Lottery/getAmKJ',
    method: 'post',
    data,
  })
}
// 读取澳门开奖历史
export function getAmOpenList(data) {
  return request({
    url: '/Lottery/getAmOpenList',
    method: 'post',
    data,
  })
}
// 读取澳门新新彩
export function getxAmOpen(data) {
  return request({
    url: '/Lottery/getxAmKJ',
    method: 'post',
    data,
  })
}
// 读取澳门新新彩开奖历史
export function getxAmOpenList(data) {
  return request({
    url: '/Lottery/getxAmOpenList',
    method: 'post',
    data,
  })
}

// 读取澳门新新彩下半场
export function getxxAmOpen(data) {
  return request({
    url: '/Lottery/getxxAmKJ',
    method: 'post',
    data,
  })
}
// 读取澳门新新彩下半场开奖历史
export function getxxAmOpenList(data) {
  return request({
    url: '/Lottery/getxxAmOpenList',
    method: 'post',
    data,
  })
}


// 读取香港开奖
// export function getXgOpen(data) {
//   return request({
//     url: '/Lottery/getXgOpen',
//     method: 'post',
//     data,
//   })
// }
export function getXgOpen(data) {
  return request({
    url: '/Lottery/getXgKJ',
    method: 'post',
    data,
  })
}

// 读取香港开奖历史
export function getXgOpenList(data) {
  return request({
    url: '/Lottery/getXgOpenList',
    method: 'post',
    data,
  })
}

// 读取港彩下一期时间
export function getXgNextTime(data) {
  return request({
    url: '/Lottery/getXgNextTime',
    method: 'post',
    data,
  })
}

// 读取港彩本月的开奖日期
export function getXgOpenDay(data) {
  return request({
    url: '/Lottery/getXgOpenDay',
    method: 'post',
    data,
  })
}

// 读取彩种getLottery
export function getLottery(data) {
  return request({
    url: '/Lottery/getLottery',
    method: 'post',
    data,
  })
}


// 台湾
export function getTwKJ(data) {
  return request({
    url: '/Lottery/getTwKJ',
    method: 'post',
    data,
  })
}

export function getTwOpenList(data) {
  return request({
    url: '/Lottery/getTwOpenList',
    method: 'post',
    data,
  })
}

// 新加坡

export function getXjbKJ(data) {
  return request({
    url: '/Lottery/getXjbKJ',
    method: 'post',
    data,
  })
}

export function getXjbOpenList(data) {
  return request({
    url: '/Lottery/getXjbOpenList',
    method: 'post',
    data,
  })
}


// 读取分页CMS内容
export function getCMSListByPage(data) {
  return request({
    url: '/Forum/getCMSListByPage',
    method: 'post',
    data,
  })
}


// 读取标签列表
export function getCMSTagList(data) {
  return request({
    url: '/Forum/getCMSTagList',
    method: 'post',
    data,
  })
}

// 所有标签数据
export function getCMSIndex(data) {
  return request({
    url: '/Forum/getCMSIndex',
    method: 'post',
    data,
  })
}

// 单标签数据
export function getCMSList(data) {
  return request({
    url: '/Forum/getCMSList',
    method: 'post',
    data,
  })
}

export function getDataPhotoCMS(data) {
  return request({
    url: '/Forum/getDataPhotoCMS',
    method: 'post',
    data,
  })
}

export function getAllGames(data) {
  return request({
    url: '/Lottery/getAllGames',
    method: 'post',
    data,
  })
}

export function getAgAllGames(data) {
  return request({
    url: '/Lottery/getAgAllGames',
    method: 'post',
    data,
  })
}

export function getPlayDetail(data) {
  return request({
    url: '/Lottery/getPlayDetail',
    method: 'post',
    data,
  })
}

export function getSscDataTime(data) {
  return request({
    url: '/Lottery/getSscDataTime',
    method: 'post',
    data,
  })
}



export function getssckjData(data) {
  return request({
    url: '/Lottery/getssckjData',
    method: 'post',
    data,
  })
}


export function getHistoryData(data) {
  return request({
    url: '/Lottery/getHistoryData',
    method: 'post',
    data,
  })
}


export function getGameOrderData(data) {
  return request({
    url: '/Lottery/getGameOrderData',
    method: 'post',
    data,
  })
}



export function addOrder(data) {
  return request({
    url: '/Lottery/addOrder',
    method: 'post',
    data,
  })
}


export function getAG(data) {
  return request({
    url: '/Lottery/getAG',
    method: 'post',
    data,
  })
}

export function getAgBalance(data) {
  return request({
    url: '/Lottery/getAgBalance',
    method: 'post',
    data,
  })
}

export function SaveAgTransfer(data) {
  return request({
    url: '/Lottery/SaveAgTransfer',
    method: 'post',
    data,
  })
}

export function getAGAllBalance(data) {
  return request({
    url: '/Lottery/getAGAllBalance',
    method: 'post',
    data
  })

}

export function getOpenCodeList(data) {
  return request({
    url: '/Lottery/getOpenCodeList',
    method: 'post',
    data
  })

}

export function getIndexGames(data) {
  return request({
    url: '/Lottery/getIndexGames',
    method: 'post',
    data
  })

}

export function getOpenCodeListDetails(data) {
  return request({
    url: '/Lottery/getOpenCodeListDetails',
    method: 'post',
    data
  })

}


