import { render, staticRenderFns } from "./UserPoint.vue?vue&type=template&id=0202112e&scoped=true&"
import script from "./UserPoint.vue?vue&type=script&lang=js&"
export * from "./UserPoint.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0202112e",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\work\\tukuv2\\home\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0202112e')) {
      api.createRecord('0202112e', component.options)
    } else {
      api.reload('0202112e', component.options)
    }
    module.hot.accept("./UserPoint.vue?vue&type=template&id=0202112e&scoped=true&", function () {
      api.rerender('0202112e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/lottery/users/UserPoint.vue"
export default component.exports