<template>
  <div class="layout-container">
    <div class="layout-content">
      <transition mode="out-in" name="fade-transform">
        <keep-alive v-if="$route.meta.keepAlive">
          <router-view></router-view>
        </keep-alive>
        <router-view v-else></router-view>
      </transition>
    </div>
    <div class="layout-footer layout-footer2">
      <TabBar :data="tabBar" @change="handleChange" />
    </div>
  </div>
</template>

<script>
import TabBar from '@/components/TabBar'

export default {
  name: 'Layouts',
  components: { TabBar },
  data() {
    return {
      tabBar: [
        {
          title: '首页',
          to: {
            name: 'Home',
          },
          icon: require('@/assets/images/home-icon.png'),
          activeIcon: require('@/assets/images/home-icon-active.png'),
        },
        {
          title: '资料库',
          to: {
            name: 'Formula',
            params: {
              id: '90005',
            },
          },
          icon: require('@/assets/images/doc-icon.png'),
          activeIcon: require('@/assets/images/doc-icon-active.png'),
        },
        {
          title: '购彩大厅',
          to: {
            name: 'Lottery',
          },
          icon: require('@/assets/images/cai-icon.svg'),
          activeIcon: require('@/assets/images/cai-icon-active.svg'),
        },
        {
          title: '图库',
          to: {
            name: 'Gallery',
            params: {
              id: '90005',
            },
          },
          icon: require('@/assets/images/platform-icon.png'),
          activeIcon: require('@/assets/images/platform-icon-active.png'),
        },
        {
          title: '我的',
          to: {
            // name: 'My',
            name: 'LotteryMy',
          },
          icon: require('@/assets/images/me-icon.png'),
          activeIcon: require('@/assets/images/me-icon-active.png'),
        },
      ],
    }
  },
  created() {},
  mounted() {
    this.datachange()
  },
  watch: {
    czid(val) {
      this.datachange()
    },
  },
  computed: {
    czid() {
      return this.$store.state.user.czid
    },
  },
  methods: {
    handleChange(v) {
      console.log('tab value:', v)
    },
    datachange() {
      let tabBar = this.tabBar
      tabBar.forEach((item) => {
        let to = item.to
        if (to.name == 'Forum' || to.name == 'Tools' || to.name == 'Gallery') {
          let curlottery = localStorage.getItem('curlottery')
          if (curlottery) {
            to.params.id = curlottery
            let type = this.getType(curlottery, to.name)
            // to.query.uType = type
          }
        }
      })
      this.tabBar = tabBar
    },

    getType(curlottery, name) {
      let type = 0
      if (name == 'Forum') {
        if (curlottery == '90001') {
          type = 4
        } else if (curlottery == '90002') {
          type = 20
        } else if (curlottery == '90004') {
          type = 12
        } else {
          type = 36
        }
      } else if (name == 'Tools') {
        if (curlottery == '90001') {
          type = 8
        } else if (curlottery == '90002') {
          type = 24
        } else if (curlottery == '90004') {
          type = 16
        } else {
          type = 40
        }
      } else if (name == 'Gallery') {
        if (curlottery == '90001') {
          type = 5
        } else if (curlottery == '90002') {
          type = 21
        } else if (curlottery == '90004') {
          type = 13
        } else {
          type = 37
        }
      }
      return type
    },
  },
}
</script>

<style lang="scss" scoped>
.layout-container {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  .layout-content {
    flex: 1;
    width: 100%;
    height: 100%;
  }
}
.layout-footer2 {
  .van-tabbar {
    height: 52px;
    ::v-deep .van-tabbar-item {
      font-size: 13px;
      .van-tabbar-item__icon {
        margin-block: 2px;
        img {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}
</style>
